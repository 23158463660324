import React, {useEffect, useState} from "react";
import {getCookie, setCookie} from "../../utils/cookie";
import {useTranslation} from "react-i18next";
import './cookieinformer.component.scss';

const CookieInformer = () => {

    const {t} = useTranslation();

    const cookieName = 'cookie-informer';
    const [isCookieSet, setIsCookieSet] = useState(!!getCookie(cookieName));
    const [hidden, setHidden] = useState(true);

    const handleClick = () => {
        setCookie(cookieName, true, 365);
        setTimeout(() => setIsCookieSet(true), 300);
        setHidden(true);
    }

    useEffect(() => {
        setTimeout(() => setHidden(isCookieSet), 300);
    }, [isCookieSet]);

    if (isCookieSet) {
        return null;
    }

    return (
        <div className={`cookie-informer ${!hidden ? 'cookie-informer--visible' : ''}`}>
            <div className="cookie-informer__text">
                {t('Components.CookieInformer.Text')}
            </div>
            <button onClick={handleClick} className="cookie-informer__button btn btn-primary btn-big button button-accent">
                {t('Components.CookieInformer.Button')}
            </button>
        </div>
    )
}

export default CookieInformer